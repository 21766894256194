import React from 'react';
import {
    AppBar,
    Typography,
    Box,
    useTheme
} from '@mui/material';

import { HeaderContainer, LogoContainer, StyledButton } from './styles';

const Header = () => {
    const theme = useTheme();

    return (
        <AppBar position="sticky" elevation={1} sx={{ backgroundColor: '#ffffff' }}>
            <HeaderContainer>
                <LogoContainer>
                    <img
                        src="/mail7-logo.webp"
                        alt="Mail7 Logo"
                        style={{ height: 40, marginRight: theme.spacing(2) }}
                    />
                    <Typography variant="h6" noWrap sx={{ color: theme.palette.primary.main }}>
                        Disposable Email Service
                    </Typography>
                </LogoContainer>
                <Box>
                    <StyledButton
                        variant="contained"
                        href="https://mail7.app"
                        target="_blank"
                        disableElevation
                    >
                        Visit Mail7
                    </StyledButton>
                </Box>
            </HeaderContainer>
        </AppBar>
    );
};

export default Header;