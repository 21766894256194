import React from 'react';
import { Box, Container, CssBaseline } from '@mui/material';
import Header from '../Header';
import { StyledMain } from './styles';

const Layout = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <Header />
            <StyledMain>
                <Container maxWidth="lg">{children}</Container>
            </StyledMain>
        </Box>
    );
};

export default Layout;