import { styled } from '@mui/material/styles';
import { Toolbar, Button } from '@mui/material';

export const HeaderContainer = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 3),
    minHeight: 70,
}));

export const LogoContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: theme.spacing(3),
    padding: theme.spacing(1, 3),
    fontWeight: 600,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: theme.shadows[2],
    },
    transition: 'all 0.2s ease-in-out',
}));