import React, { useState } from 'react';
import {
    Paper,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Email as EmailIcon,
    ContentCopy as CopyIcon,
    Refresh as RefreshIcon,
} from '@mui/icons-material';
import { EmailBoxContainer, InputContainer } from './styles';
import { toast } from 'react-hot-toast';

const DOMAIN = '@mail7.app';

const EmailBox = ({ onEmailSubmit, currentEmail }) => {
    const [localPart, setLocalPart] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (localPart.trim()) {
            const fullEmail = localPart.includes('@') ? localPart : `${localPart}${DOMAIN}`;
            onEmailSubmit(fullEmail.toLowerCase());
        }
    };

    const handleRandomEmail = () => {
        const randomString = Math.random().toString(36).substring(2, 8);
        const newEmail = `${randomString}${DOMAIN}`;
        setLocalPart(randomString);
        onEmailSubmit(newEmail);
    };

    const copyToClipboard = () => {
        const emailToCopy = currentEmail || (localPart ? `${localPart}${DOMAIN}` : '');
        if (emailToCopy) {
            navigator.clipboard.writeText(emailToCopy)
                .then(() => toast.success('Email copied to clipboard'))
                .catch(() => toast.error('Failed to copy email'));
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value.replace(DOMAIN, '');
        setLocalPart(value.replace(/\s+/g, ''));
    };

    const displayValue = currentEmail || (localPart ? `${localPart}${DOMAIN}` : '');

    return (
        <EmailBoxContainer>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Get Your Disposable Email
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Just enter your desired username - we'll add @mail7.app automatically!
                </Typography>

                <form onSubmit={handleSubmit}>
                    <InputContainer>
                        <TextField
                            fullWidth
                            value={displayValue}
                            onChange={handleInputChange}
                            placeholder="username"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon color="action" />
                                    </InputAdornment>
                                ),
                                endAdornment: displayValue && (
                                    <InputAdornment position="end">
                                        <Tooltip title="Copy email">
                                            <IconButton onClick={copyToClipboard} edge="end">
                                                <CopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!localPart.trim()}
                        >
                            Open Inbox
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleRandomEmail}
                            startIcon={<RefreshIcon />}
                        >
                            Random
                        </Button>

                    </InputContainer>
                </form>
            </Paper>
        </EmailBoxContainer>
    );
};

export default EmailBox;
