import { styled } from '@mui/material/styles';
import { Paper, Box } from '@mui/material';

export const InboxContainer = styled(Paper)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}));

export const ListContainer = styled(Box)({
    flex: 1,
    overflow: 'auto',
});

export const EmptyState = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    textAlign: 'center',
}));