import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_WORKER_URL || 'https://mail7-email-receiver.gracker-account.workers.dev',
});

export const emailService = {
    async getEmails(address) {
        try {
            const response = await api.get(`/api/emails?address=${address}`);
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to fetch emails');
        }
    },

    async getEmailContent(key) {
        try {
            const response = await api.get(`/api/email?key=${key}`);
            return response.data;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to fetch email content');
        }
    },
};
