import { useState, useCallback } from 'react';
import { emailService } from '../services/api';

export const useEmails = () => {
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emailContent, setEmailContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchEmailContent = useCallback(async (key) => {
        try {
            setLoading(true);
            const content = await emailService.getEmailContent(key);
            setEmailContent(content);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        selectedEmail,
        emailContent,
        loading,
        error,
        setSelectedEmail,
        fetchEmailContent
    };
};