import { styled } from '@mui/material/styles';
import { Paper, Box } from '@mui/material';

export const ViewerContainer = styled(Paper)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}));

export const EmailHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
}));

export const ContentPanel = styled(Box)(({ theme }) => ({
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    '& img': {
        maxWidth: '100%',
        height: 'auto',
    },
}));