import React, { useState } from 'react';
import {
    Typography,
    Box,
    Tabs,
    Tab,
    CircularProgress,
    IconButton,
    Button,
    Divider,
    Tooltip
} from '@mui/material';
import {
    ContentCopy as CopyIcon,
    Download as DownloadIcon,
    OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { format } from 'date-fns';
import { ViewerContainer, EmailHeader, ContentPanel } from './styles';
import DOMPurify from 'dompurify';
import { toast } from 'react-hot-toast';

const EmailViewer = ({ email, content, loading }) => {
    const [activeTab, setActiveTab] = useState('html');

    if (!email) {
        return (
            <ViewerContainer>
                <Box sx={{ p: 4, textAlign: 'center', color: 'text.secondary' }}>
                    <Typography>Select an email to view its content</Typography>
                </Box>
            </ViewerContainer>
        );
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => toast.success('Copied to clipboard'))
            .catch(() => toast.error('Failed to copy'));
    };

    const handleDownloadAttachment = (attachment) => {
        const link = document.createElement('a');
        link.href = `data:${attachment.contentType};base64,${attachment.content}`;
        link.download = attachment.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const openInNewWindow = () => {
        const win = window.open('', '_blank');
        win.document.write(`
      <html>
        <head>
          <title>${email.subject}</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 20px; }
          </style>
        </head>
        <body>
          ${content?.html || content?.text || ''}
        </body>
      </html>
    `);
    };

    return (
        <ViewerContainer>
            <EmailHeader>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">{email.subject}</Typography>
                    <Typography variant="body2" color="text.secondary">
                        From: {email.from}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Date: {format(new Date(email.receivedAt), 'PPpp')}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="Copy content">
                        <IconButton onClick={() => handleCopy(content?.text || '')}>
                            <CopyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Open in new window">
                        <IconButton onClick={openInNewWindow}>
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </EmailHeader>

            <Divider />

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
                    {content?.html && <Tab label="HTML" value="html" />}
                    <Tab label="Text" value="text" />
                    <Tab label="Raw" value="raw" />
                </Tabs>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <ContentPanel>
                    {activeTab === 'html' && content?.html && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(content.html)
                            }}
                        />
                    )}
                    {activeTab === 'text' && (
                        <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                            {content?.text}
                        </pre>
                    )}
                    {activeTab === 'raw' && (
                        <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                            {JSON.stringify(content, null, 2)}
                        </pre>
                    )}
                </ContentPanel>
            )}

            {email.attachments?.length > 0 && (
                <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Attachments ({email.attachments.length})
                    </Typography>
                    {email.attachments.map((attachment, index) => (
                        <Button
                            key={index}
                            startIcon={<DownloadIcon />}
                            onClick={() => handleDownloadAttachment(attachment)}
                            sx={{ mr: 1, mb: 1 }}
                        >
                            {attachment.filename}
                        </Button>
                    ))}
                </Box>
            )}
        </ViewerContainer>
    );
};

export default EmailViewer;