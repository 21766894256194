import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { theme } from './styles/theme';
import Layout from './components/Layout';
import EmailBox from './components/EmailBox';
import Inbox from './components/Inbox';
import EmailViewer from './components/EmailViewer';
import { useEmailPolling } from './hooks/useEmailPolling';
import { useEmails } from './hooks/useEmails';
import { Grid } from '@mui/material';

function App() {
  const [currentEmail, setCurrentEmail] = useState('');
  const { emails, loading, error, refetch } = useEmailPolling(currentEmail);
  const {
    selectedEmail,
    emailContent,
    loading: contentLoading,
    setSelectedEmail,
    fetchEmailContent
  } = useEmails();

  const handleEmailSubmit = (email) => {
    setCurrentEmail(email);
    setSelectedEmail(null);
  };

  const handleSelectEmail = (email) => {
    setSelectedEmail(email);
    if (email?.key) {
      fetchEmailContent(email.key);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <EmailBox
          onEmailSubmit={handleEmailSubmit}
          currentEmail={currentEmail}
        />
        {currentEmail && (
          <Grid container spacing={3} sx={{ height: 'calc(100vh - 250px)' }}>
            <Grid item xs={12} md={4}>
              <Inbox
                emails={emails}
                loading={loading}
                error={error}
                onRefresh={refetch}
                onSelectEmail={handleSelectEmail}
                selectedEmailId={selectedEmail?.id}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <EmailViewer
                email={selectedEmail}
                content={emailContent}
                loading={contentLoading}
              />
            </Grid>
          </Grid>
        )}
        <Toaster position="bottom-right" />
      </Layout>
    </ThemeProvider>
  );
}

export default App;
