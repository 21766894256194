import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    IconButton,
    Chip,
    Divider,
    CircularProgress,
} from '@mui/material';
import {
    Refresh as RefreshIcon,
    OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { InboxContainer, ListContainer, EmptyState } from './styles';

const Inbox = ({
    emails,
    loading,
    error,
    onRefresh,
    onSelectEmail,
    selectedEmailId
}) => {
    if (error) {
        return (
            <EmptyState>
                <Typography color="error">{error}</Typography>
            </EmptyState>
        );
    }

    if (!emails.length && !loading) {
        return (
            <EmptyState>
                <Typography color="textSecondary">
                    No emails received yet. Waiting for incoming messages...
                </Typography>
                <IconButton onClick={onRefresh} color="primary" sx={{ mt: 2 }}>
                    <RefreshIcon />
                </IconButton>
            </EmptyState>
        );
    }

    return (
        <InboxContainer>
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h6" sx={{ flex: 1 }}>
                    Inbox
                </Typography>
                <IconButton onClick={onRefresh} disabled={loading}>
                    <RefreshIcon />
                </IconButton>
            </Box>
            <Divider />
            <ListContainer>
                {loading && !emails.length ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {emails.map((email) => (
                            <React.Fragment key={email.id}>
                                <ListItem
                                    button
                                    selected={selectedEmailId === email.id}
                                    onClick={() => onSelectEmail(email)}
                                >
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {email.subject}
                                                {email.attachments?.length > 0 && (
                                                    <Chip
                                                        label={`${email.attachments.length} attachments`}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                )}
                                            </Box>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {email.from}
                                                </Typography>
                                                {' — '}
                                                {format(new Date(email.receivedAt), 'PPpp')}
                                            </>
                                        }
                                    />
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(`/email/${email.id}`, '_blank');
                                        }}
                                    >
                                        <OpenInNewIcon />
                                    </IconButton>
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </ListContainer>
        </InboxContainer>
    );
};

export default Inbox;