import { useState, useEffect, useCallback } from 'react';
import { emailService } from '../services/api';

export const useEmailPolling = (emailAddress, pollInterval = 5000) => {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchEmails = useCallback(async () => {
        if (!emailAddress) return;

        try {
            setLoading(true);
            const data = await emailService.getEmails(emailAddress);
            setEmails(data.emails);
            setError(null);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [emailAddress]);

    useEffect(() => {
        if (emailAddress) {
            fetchEmails();
            const interval = setInterval(fetchEmails, pollInterval);
            return () => clearInterval(interval);
        }
    }, [emailAddress, fetchEmails, pollInterval]);

    return {
        emails,
        loading,
        error,
        refetch: fetchEmails
    };
};