import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const EmailBoxContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const InputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));